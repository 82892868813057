import React, { useState } from "react";
import style from "./InputText.module.css";

export const InputText = ({ propmt, name, setting = {}, onChange }) => {
    const { require = false, focus = false, password = false } = setting;
    const [inputValue, setInputValue] = useState("");
    const handleChange = (event) => {
        const newValue = event.target.value;
        setInputValue(newValue);
        if (onChange) {
            onChange(newValue);
        }
    };

    return (
        <div
            className={`${style.inputcontainer} ${
                inputValue === "" ? style.type : style.type
            }`}
        >
            <input
                type={password ? "password" : "text"}
                id={name}
                name={name}
                autoComplete={name}
                required={require}
                autoFocus={focus}
                value={inputValue}
                onChange={handleChange}
                placeholder=" "
            />
            <label>{propmt}</label>
        </div>
    );
};
