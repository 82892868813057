import React, { useEffect, useRef, useState } from "react";
import loginstyle from "./BackendLogin.module.css";
import mainstyle from "./Backend.module.css";
import { backendurl } from "../assests/globalVariable";

export const Backend = () => {
    const [isLogin, setIsLogin] = useState(false);

    return isLogin ? <Main /> : <Login login={setIsLogin} />;
};

const Login = ({ login }) => {
    const usernameRef = useRef(null);
    const passwordRef = useRef(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const username = usernameRef.current.value;
        const password = passwordRef.current.value;
        try {
            const response = await fetch(backendurl + "backend/login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ username, password }),
            });

            if (response.ok) {
                login(true);
            } else {
                const data = await response.json();
                alert(data.message);
            }
        } catch (error) {
            console.error("登入過程中發生錯誤", error);
        }
    };

    return (
        <div className={loginstyle.body}>
            <div className={loginstyle.main}>
                <div className={loginstyle.head}>三元及第線上測驗後台</div>
                <form
                    id="logindiv"
                    className={loginstyle.inputs}
                    onSubmit={handleSubmit}
                >
                    <div className={loginstyle["input-container"]}>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            ref={usernameRef}
                            required
                            autoFocus
                        />
                        <label>帳號:</label>
                    </div>
                    <div className={loginstyle["input-container"]}>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            required
                            ref={passwordRef}
                        />
                        <label>密碼:</label>
                    </div>
                    <input
                        type="submit"
                        value="登入"
                        id="Button1"
                        className={loginstyle.bt}
                    />
                </form>
            </div>
        </div>
    );
};

const Main = () => {
    const [page, setPage] = useState(1);
    const [exams, setExams] = useState([]);

    const loadExams = () => {
        fetch(backendurl + "backend/getExams", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                password: "817482",
            }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json().then((data) => {
                        throw new Error(data.message);
                    });
                }
            })
            .then((data) => {
                setExams(data.message);
            })
            .catch((error) => {
                alert(error.message);
            });
    };

    useEffect(() => {
        loadExams();
        return () => {};
    }, []);

    return (
        <div className={mainstyle.background}>
            <div className={mainstyle.menu}>
                <div className={mainstyle.title}>三元及第線上測驗後台</div>
                <div
                    className={`${mainstyle.menuitem} ${
                        page === 1 ? mainstyle.selected : null
                    }`}
                    onClick={() => {
                        setPage(1);
                    }}
                >
                    <div className={mainstyle.menuitem_word}>測驗建立</div>
                </div>
                <div
                    className={`${mainstyle.menuitem} ${
                        page === 2 ? mainstyle.selected : null
                    }`}
                    onClick={() => {
                        setPage(2);
                    }}
                >
                    <div className={mainstyle.menuitem_word}>測驗檢視</div>
                </div>
                <div
                    className={`${mainstyle.menuitem} ${
                        page === 3 ? mainstyle.selected : null
                    }`}
                    onClick={() => {
                        setPage(3);
                    }}
                >
                    <div className={mainstyle.menuitem_word}>問題回覆</div>
                </div>
            </div>
            <div
                className={`${mainstyle.page} ${
                    page === 1 ? mainstyle.selected : null
                }`}
            >
                <Page1 />
            </div>
            <div
                className={`${mainstyle.page} ${
                    page === 2 ? mainstyle.selected : null
                }`}
            >
                <Page2 exams={exams} loadExams={loadExams} />
            </div>
            <div
                className={`${mainstyle.page} ${
                    page === 3 ? mainstyle.selected : null
                }`}
            >
                <Page3 />
            </div>
        </div>
    );
};

const Page1 = () => {
    const yearRef = useRef(null);
    const monthRef = useRef(null);
    const keyRef = useRef(null);
    const nameRef = useRef(null);
    const frontlineRef = useRef(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        let year = yearRef.current.value;
        const month = monthRef.current.value;
        const key = keyRef.current.value;
        const name = nameRef.current.value;
        const frontline = frontlineRef.current.value;
        year = (parseInt(year) + 1911) % 100;
        try {
            const response = await fetch(backendurl + "backend/createExam", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    year: year.toString(),
                    month,
                    key,
                    name,
                    frontline,
                    password: "817482",
                }),
            });

            if (response.ok) {
                alert("建立成功");
                yearRef.current.value = "";
                monthRef.current.value = "";
                keyRef.current.value = "";
                nameRef.current.value = "";
                frontlineRef.current.value = "";
            } else {
                const data = await response.json();
                console.log(data);
                alert(data.message);
            }
        } catch (error) {
            console.error("登入過程中發生錯誤", error);
        }
    };

    return (
        <form className={mainstyle.page1} onSubmit={handleSubmit}>
            <div className={mainstyle.block1}>
                <div className={loginstyle["input-container"]}>
                    <input
                        type="text"
                        id="Examyear"
                        name="Examyear"
                        ref={yearRef}
                        required
                        autoFocus
                        maxLength={3}
                    />
                    <label>年度:</label>
                </div>
                <div className={loginstyle["input-container"]}>
                    <input
                        type="text"
                        id="Exammonth"
                        name="Exammonth"
                        ref={monthRef}
                        required
                        maxLength={1}
                    />
                    <label>月份:</label>
                </div>
            </div>
            <div className={mainstyle.block2}>
                <div className={loginstyle["input-container"]}>
                    <input
                        type="text"
                        id="Examkey"
                        name="Examkey"
                        ref={keyRef}
                        required
                        maxLength={2}
                    />
                    <label>代碼:</label>
                </div>
                <div className={loginstyle["input-container"]}>
                    <input
                        type="text"
                        id="Examname"
                        name="Examname"
                        ref={nameRef}
                        required
                    />
                    <label>名稱:</label>
                </div>
            </div>
            <div className={mainstyle.block3}>
                <div className={mainstyle.frontline}>
                    <textarea
                        id="Examfrontline"
                        name="Examfrontline"
                        ref={frontlineRef}
                        required
                    />
                    <label>前言:</label>
                </div>
            </div>
            <div className={mainstyle.block4}>
                <input
                    type="submit"
                    value="建立測驗"
                    id="Button1"
                    className={mainstyle.uploadbt}
                />
            </div>
        </form>
    );
};

const Page2 = ({ exams, loadExams }) => {
    const [selectedExam, setSelectedExam] = useState("");
    const [showEdit, setShowEdit] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [detailExam, setDetailExam] = useState(null);
    const schedulesFileRef = useRef(null);
    const questionsFileRef = useRef(null);
    const nameRef = useRef(null);
    const frontlineRef = useRef(null);
    const getValueOrDefault = (obj, key) => {
        return obj.hasOwnProperty(key) ? obj[key] : "";
    };
    const quesitonLength = (schedules) => {
        if (!schedules) {
            return 0;
        }
        return schedules.reduce((totalLength, schedule) => {
            return (
                totalLength +
                (schedule.questions ? schedule.questions.length : 0)
            );
        }, 0);
    };

    const changeEnable = async (exam) => {
        const name = exam.name;
        const frontline = exam.frontline;
        const enable = !exam.enable;
        try {
            const response = await fetch(backendurl + "backend/editExam", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    _id: exam._id,
                    name,
                    frontline,
                    enable,
                    password: "817482",
                }),
            });

            if (response.ok) {
                alert("更新成功");
                loadExams();
            } else {
                const data = await response.json();
                console.log(data);
                alert(data.message);
            }
        } catch (error) {
            console.error("過程中發生錯誤", error);
        }
    };
    const editName = (exam) => {
        setShowEdit(true);
        setSelectedExam(exam);
        nameRef.current.value = exam.name;
        frontlineRef.current.value = exam.frontline;
    };
    const importSchedules = (exam) => {
        if (
            exam["schedules"].length === 0 ||
            window.confirm("已有讀書計畫，是否覆蓋紀錄?")
        ) {
            setSelectedExam(exam._id);
            schedulesFileRef.current.click();
        }
    };
    const importQuestions = (exam) => {
        setSelectedExam(exam._id);
        questionsFileRef.current.click();
    };
    const displayQuestions = (exam) => {
        setDetailExam(exam["schedules"]);
    };
    useEffect(() => {
        if (detailExam !== null) {
            setShowDetail(true);
        }
        return () => {};
    }, [detailExam]);

    const deleteExam = async (exam) => {
        try {
            const response = await fetch(backendurl + "backend/delExam", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    _id: exam._id,
                    password: "817482",
                }),
            });

            if (response.ok) {
                alert("更新成功");
                loadExams();
            } else {
                const data = await response.json();
                console.log(data);
                alert(data.message);
            }
        } catch (error) {
            console.error("過程中發生錯誤", error);
        }
    };

    const handleSubmitS = async (event) => {
        event.preventDefault();
        const file = schedulesFileRef.current.files[0];
        if (!file) {
            alert("No file selected!");
            return;
        }

        const formData = new FormData();
        formData.append("examid", selectedExam);
        formData.append("excelFile", file);
        formData.append("password", "817482");

        const response = await fetch(backendurl + "backend/importSchedules", {
            method: "POST",
            body: formData,
        });

        if (response.ok) {
            alert("成功匯入");
            loadExams();
        } else {
            const data = await response.json();
            alert(data);
        }
        schedulesFileRef.current.value = "";
    };
    const handleSubmitQ = async (event) => {
        event.preventDefault();
        const file = questionsFileRef.current.files[0];
        if (!file) {
            alert("No file selected!");
            return;
        }

        const formData = new FormData();
        formData.append("examid", selectedExam);
        formData.append("excelFile", file);
        formData.append("password", "817482");

        const response = await fetch(backendurl + "backend/importQuestions", {
            method: "POST",
            body: formData,
        });
        const data = await response.json();
        if (response.ok) {
            alert("成功匯入。" + data.message);
            loadExams();
        } else {
            alert(data);
            console.log(data);
        }
        questionsFileRef.current.value = "";
    };
    const handleSubmitE = async (event) => {
        event.preventDefault();

        const name = nameRef.current.value;
        const frontline = frontlineRef.current.value;
        const enable = selectedExam.enable;

        try {
            const response = await fetch(backendurl + "backend/editExam", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    _id: selectedExam._id,
                    name,
                    frontline,
                    enable,
                    password: "817482",
                }),
            });

            if (response.ok) {
                alert("更新成功");
                setShowEdit(false);
                loadExams();
            } else {
                const data = await response.json();
                console.log(data);
                alert(data.message);
            }
        } catch (error) {
            console.error("過程中發生錯誤", error);
        }
    };

    return (
        <div className={mainstyle.page2}>
            <table>
                <tbody>
                    <tr>
                        <td>測驗代號</td>
                        <td>測驗名稱</td>
                        <td>日期數量</td>
                        <td>題目數量</td>
                        <td>開放與否</td>
                        <td>測驗人數</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    {[...exams].map((exam, index) => {
                        return (
                            <tr key={`row${index}`}>
                                <td>{getValueOrDefault(exam, "_id")}</td>
                                <td>{getValueOrDefault(exam, "name")}</td>
                                <td>
                                    {exam["schedules"]
                                        ? exam["schedules"].length
                                        : "尚無資料"}
                                </td>
                                <td>{quesitonLength(exam["schedules"])}</td>
                                <td>
                                    <div
                                        className={mainstyle.clickablediv}
                                        onClick={() => {
                                            changeEnable(exam);
                                        }}
                                    >
                                        {getValueOrDefault(exam, "enable")
                                            ? "是"
                                            : "否"}
                                    </div>
                                </td>
                                <td>
                                    {exam["rank"]
                                        ? exam["rank"].length
                                        : "尚無資料"}
                                </td>
                                <td>
                                    <div
                                        className={mainstyle.clickablediv}
                                        onClick={() => {
                                            editName(exam);
                                        }}
                                    >
                                        編輯前言/名稱
                                    </div>
                                </td>
                                <td>
                                    <div
                                        className={mainstyle.clickablediv}
                                        onClick={() => {
                                            importSchedules(exam);
                                        }}
                                    >
                                        匯入讀書計畫
                                    </div>
                                </td>
                                <td>
                                    <div
                                        className={mainstyle.clickablediv}
                                        onClick={() => {
                                            importQuestions(exam);
                                        }}
                                    >
                                        匯入試題
                                    </div>
                                </td>
                                <td>
                                    <div
                                        className={mainstyle.clickablediv}
                                        onClick={() => {
                                            displayQuestions(exam);
                                        }}
                                    >
                                        檢視內容
                                    </div>
                                </td>
                                <td>
                                    <div
                                        className={mainstyle.clickablediv}
                                        style={{ color: "var(--alarmcolor)" }}
                                        onClick={() => {
                                            deleteExam(exam);
                                        }}
                                    >
                                        刪除
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div
                className={`${mainstyle.editpage_back} ${
                    showEdit ? null : mainstyle.hidden
                }`}
            >
                <form
                    className={mainstyle.editpage_main}
                    onSubmit={handleSubmitE}
                >
                    <div className={loginstyle["input-container"]}>
                        <input
                            type="text"
                            id="Examname"
                            name="Examname"
                            ref={nameRef}
                            required
                        />
                        <label>名稱:</label>
                    </div>
                    <div
                        className={mainstyle.frontline}
                        style={{ height: "60%" }}
                    >
                        <textarea
                            id="Examfrontline"
                            name="Examfrontline"
                            ref={frontlineRef}
                            required
                        />
                        <label>前言:</label>
                    </div>
                    <div
                        className={mainstyle.uploadbt}
                        style={{
                            display: "inline-flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        onClick={() => {
                            setShowEdit(false);
                        }}
                    >
                        取消
                    </div>
                    <input
                        style={{ marginLeft: "1rem" }}
                        type="submit"
                        value="更新"
                        className={mainstyle.uploadbt}
                    />
                </form>
            </div>
            <input
                type="file"
                ref={schedulesFileRef}
                style={{ display: "none" }}
                onChange={handleSubmitS}
                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
            <input
                type="file"
                ref={questionsFileRef}
                style={{ display: "none" }}
                onChange={handleSubmitQ}
                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
            {showDetail && (
                <CheckExam
                    schedules={detailExam}
                    showDetail={setShowDetail}
                    setDetailExam={setDetailExam}
                />
            )}
        </div>
    );
};

const Page3 = () => {
    const [selectedReport, setSelectedReport] = useState({});
    const [reports, setReports] = useState([]);
    const [showEdit, setShowEdit] = useState(false);
    const [reportStatus, setReportStatus] = useState(0);
    const [responsePics, setResponsePics] = useState([]);
    const pictureUploader = useRef(null);
    const Responseref = useRef(null);

    const loadReports = () => {
        fetch(backendurl + "backend/getReports", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                password: "817482",
            }),
        })
            .then(async (response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json().then((data) => {
                        throw new Error(data.message);
                    });
                }
            })
            .then((data) => {
                setReports(data.message);
            })
            .catch((error) => {
                alert(error.message);
            });
    };
    useEffect(() => {
        loadReports();
        return () => {};
    }, []);
    useEffect(() => {
        setResponsePics(selectedReport.picture ? selectedReport.picture : []);
        return () => {};
    }, [selectedReport]);

    const getValueOrDefault = (obj, key) => {
        return obj.hasOwnProperty(key) ? obj[key] : "";
    };
    const switchStatus = (value) => {
        switch (value) {
            case "0":
                return "未回覆";

            case "1":
                return "已回覆";

            case "2":
                return "待查詢";

            default:
                return "未回覆";
        }
    };

    const uploadPic = () => {
        pictureUploader.current.click();
    };
    const editReport = (report) => {
        setShowEdit(true);
        setSelectedReport(report);
        setReportStatus(report.status);
        Responseref.current.value = report.response ? report.response : "";
    };
    const deleteReport = async (report) => {
        try {
            const response = await fetch(backendurl + "backend/delReport", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    _id: report._id,
                    password: "817482",
                }),
            });

            if (response.ok) {
                alert("更新成功");
                loadReports();
            } else {
                const data = await response.json();
                console.log(data);
                alert(data.message);
            }
        } catch (error) {
            console.error("過程中發生錯誤", error);
        }
    };
    const delPic = async (pic) => {
        try {
            const response = await fetch(backendurl + "backend/delPicture", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    _id: selectedReport._id,
                    pic,
                    password: "817482",
                }),
            });

            if (response.ok) {
                alert("成功刪除");
                setResponsePics((prev) =>
                    prev.filter((picture) => picture !== pic)
                );
                loadReports();
            } else {
                const data = await response.json();
                console.log(data);
                alert(data.message);
            }
        } catch (error) {
            console.error("過程中發生錯誤", error);
        }
    };

    const sortby = (col) => {
        const sortedReports = [...reports].sort((a, b) => {
            if (a[col] < b[col]) return -1;
            if (a[col] > b[col]) return 1;
            return 0;
        });
        setReports(sortedReports);
    };

    const handleSubmitP = (event) => {
        event.preventDefault();
        const file = pictureUploader.current.files[0];
        if (!file) {
            alert("No file selected!");
            return;
        }

        const formData = new FormData();
        formData.append("id", selectedReport._id);
        formData.append("picture", file);
        formData.append("password", "817482");

        fetch(backendurl + "backend/uploadPicture", {
            method: "POST",
            body: formData,
        })
            .then(async (response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json().then((data) => {
                        throw new Error(data);
                    });
                }
            })
            .then((data) => {
                setResponsePics((prev) => [...prev, data]);
            })
            .catch((error) => {
                alert(error);
            });
        pictureUploader.current.value = "";
    };
    const handleSubmitE = async (event) => {
        event.preventDefault();
        console.log(selectedReport._id);
        try {
            const response = await fetch(
                backendurl + "backend/responseReport",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        id: selectedReport._id,
                        status: reportStatus,
                        response: Responseref.current.value,
                        password: "817482",
                    }),
                }
            );
            const data = await response.json();
            console.log(data);
            if (response.ok) {
                alert("更新成功");
                loadReports();
                setShowEdit(false);
            } else {
                alert(data.message);
            }
        } catch (error) {
            console.error("過程中發生錯誤", error);
        }
    };
    return (
        <div className={mainstyle.page2}>
            <table>
                <tbody>
                    <tr>
                        <td
                            className={mainstyle.clickablediv}
                            onClick={() => {
                                sortby("userid");
                            }}
                        >
                            學生代碼
                        </td>
                        <td
                            className={mainstyle.clickablediv}
                            onClick={() => {
                                sortby("examid");
                            }}
                        >
                            測驗名稱
                        </td>
                        <td
                            className={mainstyle.clickablediv}
                            onClick={() => {
                                sortby("date");
                            }}
                        >
                            考試日期
                        </td>
                        <td
                            className={mainstyle.clickablediv}
                            onClick={() => {
                                sortby("subject");
                            }}
                        >
                            考試科目
                        </td>
                        <td
                            className={mainstyle.clickablediv}
                            onClick={() => {
                                sortby("status");
                            }}
                        >
                            回覆狀態
                        </td>
                        <td
                            className={mainstyle.clickablediv}
                            onClick={() => {
                                sortby("time");
                            }}
                        >
                            留言時間
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                    {[...reports].map((report, index) => {
                        return (
                            <tr key={`row${index}`}>
                                <td>{getValueOrDefault(report, "userid")}</td>
                                <td>{getValueOrDefault(report, "examid")}</td>
                                <td>{getValueOrDefault(report, "date")}</td>
                                <td>{getValueOrDefault(report, "subject")}</td>
                                <td>{switchStatus(report["status"])}</td>
                                <td>{getValueOrDefault(report, "time")}</td>
                                <td>
                                    <div
                                        className={mainstyle.clickablediv}
                                        onClick={() => {
                                            editReport(report);
                                        }}
                                    >
                                        編輯
                                    </div>
                                </td>
                                <td>
                                    <div
                                        className={mainstyle.clickablediv}
                                        style={{ color: "var(--alarmcolor)" }}
                                        onClick={() => {
                                            deleteReport(report);
                                        }}
                                    >
                                        刪除
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div
                className={`${mainstyle.editpage_back} ${
                    showEdit ? null : mainstyle.hidden
                }`}
            >
                <form
                    className={mainstyle.editpage_main}
                    onSubmit={handleSubmitE}
                    style={{ overflowY: "scroll" }}
                >
                    <div className={mainstyle.reportStatus}>
                        <span className={mainstyle.statustitle}>學生代碼</span>
                        {selectedReport.userid}
                    </div>
                    <div className={mainstyle.reportStatus}>
                        <span className={mainstyle.statustitle}>考試代碼</span>
                        {selectedReport.examid}
                    </div>
                    <div className={mainstyle.reportStatus}>
                        <span className={mainstyle.statustitle}>考試日期</span>
                        {selectedReport.date}
                    </div>
                    <div className={mainstyle.reportStatus}>
                        <span className={mainstyle.statustitle}>考試科目</span>
                        {selectedReport.subject}
                    </div>
                    <div className={mainstyle.reportStatus}>
                        <span className={mainstyle.statustitle}>考試題號</span>
                        {selectedReport.number}
                    </div>
                    <div className={mainstyle.reportStatus}>
                        <span className={mainstyle.statustitle}>問題內容</span>
                        {selectedReport.report}
                    </div>
                    <div className={mainstyle.reportStatus}>
                        <span className={mainstyle.statustitle}>回覆狀態</span>
                        <select
                            className={mainstyle.reportStatus}
                            value={reportStatus}
                            onChange={(e) => {
                                setReportStatus(e.target.value);
                            }}
                        >
                            <option value={0}>未回覆</option>
                            <option value={1}>已回覆</option>
                            <option value={2}>待查詢</option>
                        </select>
                    </div>

                    <div
                        className={mainstyle.response}
                        style={{ height: "40%" }}
                    >
                        <textarea
                            id="ReportResponse"
                            name="ReportResponse"
                            ref={Responseref}
                            required
                        />
                        <label>回覆:</label>
                    </div>
                    <div
                        className={mainstyle.uploadbt}
                        style={{
                            display: "inline-flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        onClick={() => {
                            setShowEdit(false);
                        }}
                    >
                        取消
                    </div>
                    <div
                        className={mainstyle.uploadbt}
                        style={{
                            display: "inline-flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: "1rem",
                            width: "7rem",
                        }}
                        onClick={() => {
                            uploadPic();
                        }}
                    >
                        上傳相片
                    </div>
                    <input
                        style={{ marginLeft: "1rem" }}
                        type="submit"
                        value="更新"
                        className={mainstyle.uploadbt}
                    />
                    <br />
                    <br />
                    {[...responsePics].map((pic) => {
                        return (
                            <img
                                key={pic}
                                onClick={() => {
                                    delPic(pic);
                                }}
                                className={mainstyle.responsepic}
                                alt=""
                                src={backendurl + "reportPics/" + pic}
                            />
                        );
                    })}
                </form>
            </div>
            <input
                type="file"
                ref={pictureUploader}
                style={{ display: "none" }}
                onChange={handleSubmitP}
                accept="image/*"
            />
        </div>
    );
};

const CheckExam = ({ schedules, showDetail, setDetailExam }) => {
    const SmallBlock = ({ schedule }) => {
        const [show, setShow] = useState(false);
        return (
            <div className={`${mainstyle.scheduleblock}`}>
                <div className={mainstyle.schedulerow}>{schedule.date}</div>
                <div className={mainstyle.schedulerow}>
                    {schedule.subjectno}.{schedule.subjectname}
                </div>
                <div
                    className={`${mainstyle.schedulecollapse} ${
                        show ? mainstyle.show : null
                    }`}
                >
                    <div
                        className={mainstyle.collapsebt}
                        onClick={() => setShow(!show)}
                    >
                        {show ? "收合" : "展開"}
                    </div>
                    {[...schedule.questions].map((item, index) => (
                        <div className={mainstyle.questions} key={index}>
                            {index + 1}.{item}
                        </div>
                    ))}
                </div>
            </div>
        );
    };
    return (
        <div className={`${mainstyle.scheduledisplayer}`}>
            <button
                className={mainstyle.schedulebt}
                onClick={() => {
                    showDetail(false);
                    setDetailExam(null);
                }}
            >
                <svg
                    width="2rem"
                    height="2rem"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M8.00191 9.41621C7.61138 9.02569 7.61138 8.39252 8.00191 8.002C8.39243 7.61147 9.0256 7.61147 9.41612 8.002L12.0057 10.5916L14.5896 8.00771C14.9801 7.61719 15.6133 7.61719 16.0038 8.00771C16.3943 8.39824 16.3943 9.0314 16.0038 9.42193L13.4199 12.0058L16.0039 14.5897C16.3944 14.9803 16.3944 15.6134 16.0039 16.004C15.6133 16.3945 14.9802 16.3945 14.5896 16.004L12.0057 13.42L9.42192 16.0038C9.03139 16.3943 8.39823 16.3943 8.00771 16.0038C7.61718 15.6133 7.61718 14.9801 8.00771 14.5896L10.5915 12.0058L8.00191 9.41621Z"
                        fill="var(--fontcolor)"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M23 4C23 2.34315 21.6569 1 20 1H4C2.34315 1 1 2.34315 1 4V20C1 21.6569 2.34315 23 4 23H20C21.6569 23 23 21.6569 23 20V4ZM21 4C21 3.44772 20.5523 3 20 3H4C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21H20C20.5523 21 21 20.5523 21 20V4Z"
                        fill="var(--fontcolor)"
                    />
                </svg>
            </button>
            {[...schedules].map((item, index) => (
                <SmallBlock schedule={item} key={index} />
            ))}
        </div>
    );
};
