import React from "react";
import style from "./NoteRadios.module.css";

export default function NoteRadios({
    selectedOption,
    setSelectedOption,
    className,
}) {
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };
    return (
        <div className={`${style.noteradios} ${className}`}>
            輔助作答註記
            <label className={style.noteradio}>
                <input
                    type="radio"
                    value="yellow"
                    checked={selectedOption === "yellow"}
                    onChange={handleOptionChange}
                />
                <div
                    className={`${style.shape} ${style.triangle} ${style.yellow}`}
                ></div>
            </label>
            <label className={style.noteradio}>
                <input
                    type="radio"
                    value="red"
                    checked={selectedOption === "red"}
                    onChange={handleOptionChange}
                />
                <div
                    className={`${style.shape} ${style.circle} ${style.red}`}
                ></div>
            </label>
            <label className={style.noteradio}>
                <input
                    type="radio"
                    value="blue"
                    checked={selectedOption === "blue"}
                    onChange={handleOptionChange}
                />
                <div
                    className={`${style.shape} ${style.square} ${style.blue}`}
                ></div>
            </label>
            <label>
                <input
                    type="radio"
                    value=""
                    checked={selectedOption === ""}
                    onChange={handleOptionChange}
                />
                不註記
            </label>
        </div>
    );
}
