import React, { useState, useEffect } from "react";
import { useAuth } from "../hooks/useAuth";
import style from "./Test.module.css";
import { backendurl } from "../assests/globalVariable";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import NoteRadios from "../componenets/NoteRadios";
import Controlbt from "../componenets/Controlbt";
import RecordDisplayer from "../componenets/RecordDisplayer";
import ResultDisplayer from "../componenets/ResultDisplayer";

export const Test = () => {
    const { user, updateRecord } = useAuth();
    const { examid } = useParams();
    const { state, search } = useLocation();
    const [currQues, setcurrQues] = useState(0);
    const [fontSize, setFontSize] = useState(100);
    const [questions, setQuestions] = useState([]);
    const [selectedOption, setSelectedOption] = useState("");
    const [selectedNote, setSelectedNote] = useState("");
    const [records, setRecords] = useState([]);
    const [showRecords, setShowRecords] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const queryParams = new URLSearchParams(search);
    const date = decodeURIComponent(queryParams.get("date"));
    const subjectno = queryParams.get("subject");
    const navigate = useNavigate();

    const piclink =
        "https://3dstu00.3dollars.com.tw/webRequest/stms/courseq.aspx?Getimg=";

    useEffect(() => {
        const confirmExit = (e) => {
            const message =
                "為了確保您的答案被儲存，請點擊 [結束作答] 按鈕後再離開頁面";
            e.returnValue = message;
            updateRecord(user._id, examid);
            return message;
        };
        window.addEventListener("beforeunload", confirmExit);
        return () => {
            window.removeEventListener("beforeunload", confirmExit);
        };
    }, [examid, updateRecord, user]);
    useEffect(() => {
        async function fetchQuestionsInBatches(questions) {
            const BATCH_SIZE = 20;
            const batches = [];
            for (let i = 0; i < questions.length; i += BATCH_SIZE) {
                batches.push(questions.slice(i, i + BATCH_SIZE));
            }

            const fetchPromises = batches.map((batch) =>
                fetch(backendurl + `exam/getQs`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ qids: batch }),
                }).then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        throw new Error("網路錯誤");
                    }
                })
            );

            return Promise.all(fetchPromises).then((results) => {
                const combinedData = results.flat();
                const sortedData = questions.map((item) =>
                    combinedData.find((obj) => obj.QuesINO === item)
                );
                return sortedData;
            });
        }

        fetchQuestionsInBatches(state.subject["questions"])
            .then((sortedData) => {
                setQuestions(sortedData);
            })
            .catch((error) => {
                console.error(error);
                alert(error.message);
            });

        return () => {};
    }, [state]);
    useEffect(() => {
        if (user.examrecords) {
            let data = [...user.examrecords].find(
                (record) =>
                    record.examid === examid &&
                    record.date === date &&
                    record.subjectno === subjectno
            );
            if (data) {
                console.log(`${examid} ${date} ${subjectno}`);
                console.log(user);
                setRecords(data.records);
                let d = [...data.records].find(
                    (record) => record.questionno === 0
                );
                if (d) {
                    setSelectedOption(d.answer);
                    setSelectedNote(d.mark);
                } else {
                    setSelectedOption("");
                    setSelectedNote("");
                }
            }
        }
    }, [user, examid, date, subjectno]);
    useEffect(() => {
        if (state.check) {
            setShowResult(true);
        }

        return () => {};
    }, [state]);

    const matchRecord = (currQues) => {
        let data = [...records].find(
            (record) => record.questionno === currQues
        );
        if (data) {
            setSelectedOption(data.answer);
            setSelectedNote(data.mark);
        } else {
            setSelectedOption("");
            setSelectedNote("");
        }
    };
    const tempRecord = (currQues, selectedNote, selectedOption) => {
        let data = [...records].find(
            (record) => record.questionno === currQues
        );
        if (data) {
            const updatedRecords = records.map((record) => {
                if (record.questionno === currQues) {
                    return {
                        questionno: record.questionno,
                        mark: selectedNote,
                        answer: selectedOption,
                    };
                }
                return record;
            });
            setRecords(updatedRecords);
        } else {
            setRecords((prev) => [
                ...prev,
                {
                    questionno: currQues,
                    mark: selectedNote,
                    answer: selectedOption,
                },
            ]);
        }
    };
    const uploadRecord = (currQues, selectedNote, selectedOption) => {
        fetch(backendurl + `user/writeRecords`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                userid: user._id,
                examid,
                date,
                subjectno,
                questionno: currQues,
                mark: selectedNote,
                answer: selectedOption,
            }),
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    console.log("網路錯誤");
                }
            })
            .then((data) => {
                console.log(data);
            });

        return () => {};
    };
    const showRecord = () => {
        setShowRecords((prev) => !prev);
    };

    const endTest = () => {
        uploadScore(countScore());
        setShowResult(true);
    };
    const leaveTest = () => {
        updateRecord(user._id, examid);
        navigate(-1);
    };

    const selectQ = (i) => {
        setShowRecords((prev) => !prev);
        setcurrQues(parseInt(i) - 1);
        matchRecord(parseInt(i) - 1);
    };
    const handleSelectChange = (event) => {
        setcurrQues(parseInt(event.target.value));
        matchRecord(parseInt(event.target.value));
    };
    const nextQ = () => {
        setcurrQues((prev) => prev + 1);
        matchRecord(currQues + 1);
    };
    const lastQ = () => {
        setcurrQues((prev) => prev - 1);
        matchRecord(currQues - 1);
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        tempRecord(currQues, selectedNote, event.target.value);
        uploadRecord(currQues, selectedNote, event.target.value);
    };
    const cancelOption = () => {
        setSelectedOption("");
        tempRecord(currQues, selectedNote, "");
        uploadRecord(currQues, selectedNote, "");
    };

    const handleNoteChange = (note) => {
        setSelectedNote(note);
        tempRecord(currQues, note, selectedOption);
        uploadRecord(currQues, note, selectedOption);
    };

    const increaseFontSize = () => {
        setFontSize((prevSize) => prevSize + 10);
    };
    const resetFontSize = () => {
        setFontSize(100);
    };
    const decreaseFontSize = () => {
        setFontSize((prevSize) => prevSize - 10);
    };

    const controlstyle = {
        fontSize: `${fontSize * 0.01}rem`,
    };

    const countPoint = (percentage) => {
        let formattedPercentage;

        if (percentage % 1 === 0) {
            formattedPercentage = percentage.toFixed(0);
        } else if (percentage % 1 === 0.1) {
            formattedPercentage = percentage.toFixed(1);
        } else {
            formattedPercentage = percentage.toFixed(2);
        }
        return formattedPercentage;
    };
    const optionDisplayer = (q, i) => {
        if (!q) {
            return "";
        }
        return (
            <>
                {q[`QuesDesc${i}`]}
                {q[`img${i + 1}`] === "有" ? (
                    <img
                        className={style.optionpic}
                        alt="loading"
                        src={`${piclink}${q["QuesINO"]}-${i}`}
                    ></img>
                ) : (
                    ""
                )}
            </>
        );
    };
    const countScore = () => {
        let rightCount = 0;
        [...questions].forEach((q, i) => {
            let record = records.find((record) => record.questionno === i);
            if (record && q["AnsQ"] === record.answer) {
                rightCount++;
            }
        });
        return countPoint((rightCount * 100) / questions.length);
    };
    const uploadScore = (score) => {
        fetch(backendurl + `user/writeScore`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                userid: user._id,
                examid,
                date,
                subjectno,
                score,
            }),
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    console.log("網路錯誤");
                }
            })
            .then((data) => {
                console.log(data);
            });

        return () => {};
    };

    return (
        <div className={style.page}>
            <div
                className={`${style.topbar} ${
                    showResult ? style.smalltopbar : null
                }`}
            >
                <div className={style.top1}>{state.examname}</div>
                <div className={style.top2}>{"測驗日期: " + date}</div>
                <div className={style.top3}>{"姓名: " + user["name"]}</div>
                <div className={style.top4}>
                    {!showResult && showRecords && (
                        <button
                            className={style.controlbt}
                            onClick={showRecord}
                        >
                            <svg
                                width="1rem"
                                height="1rem"
                                viewBox="0 0 24 24"
                                fill="none"
                                className={style.pic1}
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1 22C1 21.4477 1.44772 21 2 21H22C22.5523 21 23 21.4477 23 22C23 22.5523 22.5523 23 22 23H2C1.44772 23 1 22.5523 1 22Z"
                                    fill="var(--fontcolor)"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M18.3056 1.87868C17.1341 0.707107 15.2346 0.707107 14.063 1.87868L3.38904 12.5526C2.9856 12.9561 2.70557 13.4662 2.5818 14.0232L2.04903 16.4206C1.73147 17.8496 3.00627 19.1244 4.43526 18.8069L6.83272 18.2741C7.38969 18.1503 7.89981 17.8703 8.30325 17.4669L18.9772 6.79289C20.1488 5.62132 20.1488 3.72183 18.9772 2.55025L18.3056 1.87868ZM15.4772 3.29289C15.8677 2.90237 16.5009 2.90237 16.8914 3.29289L17.563 3.96447C17.9535 4.35499 17.9535 4.98816 17.563 5.37868L15.6414 7.30026L13.5556 5.21448L15.4772 3.29289ZM12.1414 6.62869L4.80325 13.9669C4.66877 14.1013 4.57543 14.2714 4.53417 14.457L4.0014 16.8545L6.39886 16.3217C6.58452 16.2805 6.75456 16.1871 6.88904 16.0526L14.2272 8.71448L12.1414 6.62869Z"
                                    fill="var(--fontcolor)"
                                />
                            </svg>
                            繼續作答
                        </button>
                    )}
                    {!showResult && !showRecords && (
                        <button
                            className={style.controlbt}
                            onClick={showRecord}
                        >
                            <svg
                                fill="var(--fontcolor)"
                                width="1.1rem"
                                height="1.1rem"
                                className={style.pic1}
                                viewBox="0 0 56 56"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M 15.5547 53.125 L 40.4453 53.125 C 45.2969 53.125 47.7109 50.6640 47.7109 45.7890 L 47.7109 24.5078 C 47.7109 21.4844 47.3828 20.1718 45.5078 18.2500 L 32.5703 5.1015 C 30.7891 3.2734 29.3359 2.8750 26.6875 2.8750 L 15.5547 2.8750 C 10.7266 2.8750 8.2891 5.3594 8.2891 10.2344 L 8.2891 45.7890 C 8.2891 50.6875 10.7266 53.125 15.5547 53.125 Z M 15.7422 49.3515 C 13.3281 49.3515 12.0625 48.0625 12.0625 45.7187 L 12.0625 10.3047 C 12.0625 7.9844 13.3281 6.6484 15.7656 6.6484 L 26.1718 6.6484 L 26.1718 20.2656 C 26.1718 23.2187 27.6718 24.6718 30.5781 24.6718 L 43.9375 24.6718 L 43.9375 45.7187 C 43.9375 48.0625 42.6953 49.3515 40.2578 49.3515 Z M 31.0000 21.1328 C 30.0859 21.1328 29.7109 20.7578 29.7109 19.8203 L 29.7109 7.3750 L 43.2109 21.1328 Z M 36.6250 31.1172 L 18.8359 31.1172 C 17.9922 31.1172 17.3828 31.7500 17.3828 32.5469 C 17.3828 33.3672 17.9922 34.0000 18.8359 34.0000 L 36.6250 34.0000 C 37.4453 34.0000 38.0781 33.3672 38.0781 32.5469 C 38.0781 31.7500 37.4453 31.1172 36.6250 31.1172 Z M 36.6250 39.2969 L 18.8359 39.2969 C 17.9922 39.2969 17.3828 39.9531 17.3828 40.7734 C 17.3828 41.5703 17.9922 42.1797 18.8359 42.1797 L 36.6250 42.1797 C 37.4453 42.1797 38.0781 41.5703 38.0781 40.7734 C 38.0781 39.9531 37.4453 39.2969 36.6250 39.2969 Z" />
                            </svg>
                            瀏覽作答情形
                        </button>
                    )}
                    {showResult && (
                        <div>
                            <span
                                className={
                                    countScore() >= 60
                                        ? style.goodScore
                                        : style.badScore
                                }
                            >
                                {countScore()}
                            </span>
                            分
                        </div>
                    )}
                    {showResult ? (
                        <button className={style.controlbt} onClick={leaveTest}>
                            <svg
                                fill="var(--fontcolor)"
                                className={style.pic1}
                                width="1.1rem"
                                height="1.1rem"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g data-name="Layer 2">
                                    <g data-name="checkmark-square">
                                        <rect
                                            width="24"
                                            height="24"
                                            opacity="0"
                                        />
                                        <path d="M20 11.83a1 1 0 0 0-1 1v5.57a.6.6 0 0 1-.6.6H5.6a.6.6 0 0 1-.6-.6V5.6a.6.6 0 0 1 .6-.6h9.57a1 1 0 1 0 0-2H5.6A2.61 2.61 0 0 0 3 5.6v12.8A2.61 2.61 0 0 0 5.6 21h12.8a2.61 2.61 0 0 0 2.6-2.6v-5.57a1 1 0 0 0-1-1z" />
                                        <path d="M10.72 11a1 1 0 0 0-1.44 1.38l2.22 2.33a1 1 0 0 0 .72.31 1 1 0 0 0 .72-.3l6.78-7a1 1 0 1 0-1.44-1.4l-6.05 6.26z" />
                                    </g>
                                </g>
                            </svg>
                            離開試題
                        </button>
                    ) : (
                        <button className={style.controlbt} onClick={endTest}>
                            <svg
                                fill="var(--fontcolor)"
                                className={style.pic1}
                                width="1.1rem"
                                height="1.1rem"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g data-name="Layer 2">
                                    <g data-name="checkmark-square">
                                        <rect
                                            width="24"
                                            height="24"
                                            opacity="0"
                                        />
                                        <path d="M20 11.83a1 1 0 0 0-1 1v5.57a.6.6 0 0 1-.6.6H5.6a.6.6 0 0 1-.6-.6V5.6a.6.6 0 0 1 .6-.6h9.57a1 1 0 1 0 0-2H5.6A2.61 2.61 0 0 0 3 5.6v12.8A2.61 2.61 0 0 0 5.6 21h12.8a2.61 2.61 0 0 0 2.6-2.6v-5.57a1 1 0 0 0-1-1z" />
                                        <path d="M10.72 11a1 1 0 0 0-1.44 1.38l2.22 2.33a1 1 0 0 0 .72.31 1 1 0 0 0 .72-.3l6.78-7a1 1 0 1 0-1.44-1.4l-6.05 6.26z" />
                                    </g>
                                </g>
                            </svg>
                            結束作答
                        </button>
                    )}
                </div>
                <div className={style.top5}>
                    {"科目: " + state.subject["name"]}
                </div>
                <div className={style.top6}>{"學號: " + user["_id"]}</div>
                {!showResult && (
                    <>
                        <div className={style.top7}>
                            {"題數: " + questions.length}
                        </div>
                        <div className={style.top8}>
                            {"已作答題數: " + records.length}
                        </div>
                        <div className={style.top9}>
                            {"未作答題數: " +
                                (questions.length - records.length)}
                        </div>
                    </>
                )}
            </div>
            {questions.length === 0 && (
                <div className={style.loaderpage}>
                    <div className={style.loader}></div>
                </div>
            )}
            {showResult && (
                <ResultDisplayer
                    questions={questions}
                    records={records}
                    piclink={piclink}
                />
            )}
            {!showResult && showRecords && (
                <RecordDisplayer
                    length={questions.length}
                    records={records}
                    selectQ={selectQ}
                />
            )}
            {!showResult && !showRecords && (
                <div className={style.testingblock}>
                    <div className={style.toolbar}>
                        <Controlbt
                            func1={lastQ}
                            func2={nextQ}
                            func3={cancelOption}
                            showUp={currQues === 0}
                            showDown={currQues === questions.length - 1}
                            className={style.controlbts}
                        />
                        <NoteRadios
                            selectedOption={selectedNote}
                            setSelectedOption={handleNoteChange}
                            className={style.noteradios}
                        />
                        <div className={style.selectQ}>
                            目前在第
                            <select
                                name="Qno"
                                id="Qno"
                                value={currQues}
                                onChange={handleSelectChange}
                                className={style.select}
                            >
                                {[...questions].map((value, index) => (
                                    <option key={index} value={index}>
                                        {index + 1}
                                    </option>
                                ))}
                            </select>
                            題
                        </div>
                    </div>
                    <div className={style.toolbar}>
                        <div className={style.words}>
                            {
                                "本科目測驗題視為單一選擇題，請就各選項中選出一個正確或最適當的作答，複選作答者，該題不予計分!"
                            }
                        </div>
                        <div className={style.controlfonts}>
                            {"字型"}
                            <button
                                className={style.controlfont}
                                onClick={increaseFontSize}
                            >
                                放大
                            </button>
                            <button
                                className={style.controlfont}
                                onClick={resetFontSize}
                            >
                                還原
                            </button>
                            <button
                                className={style.controlfont}
                                onClick={decreaseFontSize}
                            >
                                縮小
                            </button>
                            {" ( 顯示比例:" + fontSize + "% )"}
                        </div>
                    </div>
                    <div className={style.questionno}>
                        第{currQues + 1}題（
                        {countPoint(100 / questions.length)}
                        分）
                    </div>
                    <div style={controlstyle} className={style.question}>
                        {questions[currQues]
                            ? questions[currQues]["QuesDesc"]
                            : ""}
                        {questions[currQues] &&
                        questions[currQues]["img1"] === "有" ? (
                            <>
                                <br />
                                <img
                                    className={style.questionpic}
                                    alt="loading"
                                    src={`${piclink}${questions[currQues]["QuesINO"]}-0`}
                                ></img>
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className={style.options}>
                        <label style={controlstyle}>
                            <input
                                type="radio"
                                value="A"
                                checked={selectedOption === "A"}
                                onChange={handleOptionChange}
                            />
                            {optionDisplayer(questions[currQues], 1)}
                        </label>
                        <label style={controlstyle}>
                            <input
                                type="radio"
                                value="B"
                                checked={selectedOption === "B"}
                                onChange={handleOptionChange}
                            />
                            {optionDisplayer(questions[currQues], 2)}
                        </label>
                        <label style={controlstyle}>
                            <input
                                type="radio"
                                value="C"
                                checked={selectedOption === "C"}
                                onChange={handleOptionChange}
                            />
                            {optionDisplayer(questions[currQues], 3)}
                        </label>
                        <label style={controlstyle}>
                            <input
                                type="radio"
                                value="D"
                                checked={selectedOption === "D"}
                                onChange={handleOptionChange}
                            />
                            {optionDisplayer(questions[currQues], 4)}
                        </label>
                        {questions[currQues] &&
                        questions[currQues]["QuesDesc5"] !== "" ? (
                            <label style={controlstyle}>
                                <input
                                    type="radio"
                                    value="E"
                                    checked={selectedOption === "E"}
                                    onChange={handleOptionChange}
                                />
                                {optionDisplayer(questions[currQues], 5)}
                            </label>
                        ) : null}
                    </div>
                    <div className={style.toolbar}>
                        <Controlbt
                            func1={lastQ}
                            func2={nextQ}
                            func3={cancelOption}
                            showUp={currQues === 0}
                            showDown={currQues === questions.length - 1}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
