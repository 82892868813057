import React, { useEffect, useState } from "react";
import style from "./DateButton.module.css";

export const DateButton = ({ date, delay, setdate }) => {
    const [show, setshow] = useState(false);
    const [animate, setAnimate] = useState(false);
    const [isDone, setIsDone] = useState(true);

    useEffect(() => {
        let delayshow = setTimeout(() => {
            setshow(true);
        }, delay);

        return () => {
            clearTimeout(delayshow);
        };
    }, [delay]);

    const formatMonth = (inputDate) => {
        const parts = inputDate.split("/");
        const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        if (parts.length >= 3) {
            return months[Number(parts[1]) - 1];
        }
        return inputDate;
    };
    const formatDate = (inputDate) => {
        const parts = inputDate.split("/");
        if (parts.length >= 3) {
            return `${parts[2]}`;
        }
        return inputDate;
    };

    const displayDetail = () => {
        if (!date.haveTest) {
            return;
        }
        setAnimate(true);
        setdate(date);
        setTimeout(() => setAnimate(false), 1000); // 1秒後移除動畫類別
    };

    useEffect(() => {
        if ([...date.subjects].length > 0) {
            [...date.subjects].forEach((subject) => {
                if (subject.score < 0 && subject.show) {
                    return setIsDone(false);
                }
            });
        }
    }, [date]);

    return (
        <div
            className={`${style.schedule} ${show ? null : style.hidden} ${
                isDone || !show ? null : style.undone
            } `}
        >
            <div
                className={`${style.date} ${
                    date.haveTest ? style.havetest : null
                } ${animate ? style.details : null}`}
                onClick={() => {
                    displayDetail();
                }}
            >
                <span style={{ fontSize: "1rem" }}>
                    {formatMonth(date.date)}&nbsp;
                </span>
                <span style={{ fontSize: "2rem" }}>
                    {formatDate(date.date)}
                </span>
            </div>
        </div>
    );
};
