import React, { useEffect, useState } from "react";
import style from "./Schedule.module.css";

export const Schedule = ({ date, subjectnames, delay }) => {
    const [show, setshow] = useState(false);

    useEffect(() => {
        let delayshow = setTimeout(() => {
            setshow(true);
        }, delay);

        return () => {
            clearTimeout(delayshow);
        };
    }, [delay]);

    return (
        <div className={`${style.schedule} ${show ? null : style.hidden}`}>
            <div className={style.date}>{date}</div>
            <div className={style.subjects}>
                {[...subjectnames].map((item, index) => (
                    <div className={style.subject} key={index}>
                        {item}
                    </div>
                ))}
            </div>
        </div>
    );
};
