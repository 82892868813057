import React from "react";
import style from "./RecordDisplayer.module.css";
import notestyle from "./NoteRadios.module.css";

export default function RecordDisplayer({ length, records, selectQ }) {
    const numRows = Math.ceil(length / 10) * 2;
    const numCols = 10;

    const displayNote = (type) => {
        if (type === "yellow") {
            return (
                <div
                    className={`${notestyle.shape} ${notestyle.triangle} ${notestyle.yellow}`}
                ></div>
            );
        }
        if (type === "red") {
            return (
                <div
                    className={`${notestyle.shape} ${notestyle.circle} ${notestyle.red}`}
                ></div>
            );
        }
        if (type === "blue") {
            return (
                <div
                    className={`${notestyle.shape} ${notestyle.square} ${notestyle.blue}`}
                ></div>
            );
        }
        return <div></div>;
    };

    return (
        <>
            <div className={style.scrolltable}>
                <table className={style.table}>
                    <tbody>
                        {[...Array(numRows)].map((_, rowIndex) => (
                            <tr key={rowIndex} className={style.row}>
                                {rowIndex % 2 === 0 ? (
                                    <>
                                        <td className={`${style.ftd}`}>
                                            <div
                                                className={`${style.block} ${style.fblock}`}
                                            >
                                                題號
                                            </div>
                                        </td>
                                        {[...Array(numCols)].map(
                                            (_, colIndex) => {
                                                const recordIndex =
                                                    Math.floor(rowIndex / 2) *
                                                        10 +
                                                    colIndex;
                                                const record = records.find(
                                                    (record) =>
                                                        record.questionno ===
                                                        recordIndex
                                                );
                                                return (
                                                    <td
                                                        key={`num${
                                                            rowIndex + colIndex
                                                        }`}
                                                    >
                                                        {recordIndex <
                                                        length ? (
                                                            <div
                                                                className={
                                                                    style.block
                                                                }
                                                            >
                                                                {record &&
                                                                record.mark !==
                                                                    ""
                                                                    ? displayNote(
                                                                          record.mark
                                                                      )
                                                                    : null}
                                                                {"["}
                                                                <div
                                                                    className={
                                                                        style.link
                                                                    }
                                                                    onClick={() => {
                                                                        selectQ(
                                                                            recordIndex +
                                                                                1
                                                                        );
                                                                    }}
                                                                >
                                                                    {recordIndex +
                                                                        1}
                                                                </div>
                                                                {"]"}
                                                            </div>
                                                        ) : null}
                                                    </td>
                                                );
                                            }
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <td className={`${style.ftd}`}>
                                            <div
                                                className={`${style.block} ${style.fblock}`}
                                            >
                                                答案
                                            </div>
                                        </td>
                                        {[...Array(numCols)].map(
                                            (_, colIndex) => {
                                                const recordIndex =
                                                    Math.floor(rowIndex / 2) *
                                                        10 +
                                                    colIndex;
                                                const record = records.find(
                                                    (record) =>
                                                        record.questionno ===
                                                        recordIndex
                                                );
                                                return (
                                                    <td
                                                        key={`ans${
                                                            rowIndex + colIndex
                                                        }`}
                                                    >
                                                        <div
                                                            className={`${
                                                                style.block
                                                            } ${
                                                                (!record ||
                                                                    record.answer ===
                                                                        "") &&
                                                                recordIndex <
                                                                    length
                                                                    ? style.noanswer
                                                                    : null
                                                            }`}
                                                        >
                                                            {record
                                                                ? record.answer
                                                                : ""}
                                                        </div>
                                                    </td>
                                                );
                                            }
                                        )}
                                    </>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className={style.alarm}>
                {
                    "注意事項: 1.上方顯示所有題目之題號及作答答案 2.若為紅色底色表示尚未作答 "
                }
            </div>
        </>
    );
}
