import { ColorButton } from "./componenets/ColorButton";
import { AuthProvider } from "./hooks/useAuth";
import { Routes, Route } from "react-router-dom";
import { Login } from "./pages/Login";
import { Home } from "./pages/Home";
import { Test } from "./pages/Test";
import { Backend } from "./pages/Backend";
import { NotFound } from "./pages/NotFound";
import { useState } from "react";

function App() {
    const [isDarkMode, setIsDarkMode] = useState(false);
    return (
        <div className="App" theme={isDarkMode ? "dark" : "light"}>
            <ColorButton
                isDarkMode={isDarkMode}
                setIsDarkMode={setIsDarkMode}
            ></ColorButton>
            <AuthProvider>
                <Routes>
                    <Route path="/">
                        <Route path="login" element={<Login />}>
                            <Route path=":examid" element={<Login />} />
                        </Route>
                        <Route path="home" element={<Home />}>
                            <Route path=":examid" element={<Home />} />
                        </Route>
                        <Route path="test" element={<Test />}>
                            <Route path=":examid" element={<Test />} />
                        </Route>
                        <Route path="setting" element={<Backend />} />
                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Routes>
            </AuthProvider>
        </div>
    );
}

export default App;
