import React, { useState } from "react";
import style from "./EventModal.module.css";
import { useNavigate } from "react-router-dom";

export default function EventModal({
    onClose,
    eventDetails,
    examid,
    examname,
}) {
    let navigate = useNavigate();
    const [closing, setCloseing] = useState(false);

    function clicked() {
        setCloseing(true);
        setTimeout(() => {
            onClose();
        }, 1000);
    }

    function formatMonth(inputDate) {
        const parts = inputDate.split("/");
        const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        if (parts.length >= 3) {
            return months[Number(parts[1]) - 1];
        }
        return inputDate;
    }
    function formatDate(inputDate) {
        const parts = inputDate.split("/");
        if (parts.length >= 3) {
            return `${parts[2]}`;
        }
        return inputDate;
    }

    function startExam(subject, index) {
        navigate(
            `/test/${examid}?date=${encodeURIComponent(
                eventDetails.date
            )}&subject=${index}`,
            {
                state: { subject, examname, check: false },
            }
        );
    }
    function checkWrong(subject, index) {
        navigate(
            `/test/${examid}?date=${encodeURIComponent(
                eventDetails.date
            )}&subject=${index}`,
            {
                state: { subject, examname, check: true },
            }
        );
    }

    return (
        <div
            className={`${style.backdrop} ${closing ? style.fadeout : null}`}
            onClick={() => {
                clicked();
            }}
        >
            <div className={style.content} onClick={(e) => e.stopPropagation()}>
                <h2 className={style.eventDate}>
                    <span className={style.month}>
                        {formatMonth(eventDetails.date)}
                    </span>
                    <span className={style.date}>
                        {formatDate(eventDetails.date)}
                    </span>
                </h2>
                <div className={style.subjectsContainer}>
                    {eventDetails.subjects.map((subject, index) => (
                        <div key={index} className={style.subjectItem}>
                            <h3 className={style.subjecttitle}>
                                <span className={style.title1}>
                                    {subject.name.split("：")[0]}
                                </span>
                                {subject.score >= 0 && (
                                    <span className={style.score}>
                                        {subject.score + "分"}
                                    </span>
                                )}
                                <br />
                                <span className={style.title2}>
                                    {subject.name.split("：")[1]}
                                </span>
                            </h3>

                            {subject.show && (
                                <button
                                    className={style.startExambt}
                                    onClick={() => {
                                        startExam(subject, index + 1);
                                    }}
                                >
                                    開始作答
                                </button>
                            )}
                            {subject.show && subject.score >= 0 && (
                                <>
                                    <button
                                        className={style.checkExambt}
                                        onClick={() => {
                                            checkWrong(subject, index + 1);
                                        }}
                                    >
                                        檢視錯題
                                    </button>
                                </>
                            )}
                        </div>
                    ))}
                </div>
                <button
                    className={style.bt}
                    onClick={() => {
                        clicked();
                    }}
                >
                    <svg
                        width="2rem"
                        height="2rem"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M8.00191 9.41621C7.61138 9.02569 7.61138 8.39252 8.00191 8.002C8.39243 7.61147 9.0256 7.61147 9.41612 8.002L12.0057 10.5916L14.5896 8.00771C14.9801 7.61719 15.6133 7.61719 16.0038 8.00771C16.3943 8.39824 16.3943 9.0314 16.0038 9.42193L13.4199 12.0058L16.0039 14.5897C16.3944 14.9803 16.3944 15.6134 16.0039 16.004C15.6133 16.3945 14.9802 16.3945 14.5896 16.004L12.0057 13.42L9.42192 16.0038C9.03139 16.3943 8.39823 16.3943 8.00771 16.0038C7.61718 15.6133 7.61718 14.9801 8.00771 14.5896L10.5915 12.0058L8.00191 9.41621Z"
                            fill="var(--fontcolor)"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M23 4C23 2.34315 21.6569 1 20 1H4C2.34315 1 1 2.34315 1 4V20C1 21.6569 2.34315 23 4 23H20C21.6569 23 23 21.6569 23 20V4ZM21 4C21 3.44772 20.5523 3 20 3H4C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21H20C20.5523 21 21 20.5523 21 20V4Z"
                            fill="var(--fontcolor)"
                        />
                    </svg>
                </button>
            </div>
        </div>
    );
}
