import React, { useEffect, useMemo, useState, useRef } from "react";
import style from "./Home.module.css";
import { useAuth } from "../hooks/useAuth";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DateButton } from "../componenets/DateButton";
import EventModal from "../componenets/EventModal";
import StatisticBlock from "../componenets/StatisticBlock";
import ReportModal from "../componenets/ReportModal";
import { backendurl } from "../assests/globalVariable";

export const Home = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const { examid } = useParams();
    const { state } = useLocation();
    const [selectedDate, setSelectedDate] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [reports, setReports] = useState([]);
    const [showReport, setShowReport] = useState(false);
    const [unRead, setUnRead] = useState(false);
    const topRef = useRef(null);
    const bottomRef = useRef(null);

    //examdata
    useEffect(() => {
        if (examid === undefined) {
            navigate("/notfound");
        }
        if (!state || !state.schedules) {
            alert("登陸時間過長，請重新登錄");
            navigate("/login/" + examid);
        }
    }, [state, navigate, examid]);

    //dates
    const generatedDates = useMemo(() => {
        if (!state || !state.schedules) {
            return []; // 返回一个空数组，或其他适当的默认值
        }

        function generateDates(dates) {
            const startDate = new Date(dates[0]);
            const endDate = new Date(dates[dates.length - 1]);
            const result = [];

            startDate.setDate(startDate.getDate() - startDate.getDay() + 1);
            let adddate = endDate.getDay() === 0 ? 0 : 7 - endDate.getDay();
            endDate.setDate(endDate.getDate() + adddate);

            const formatDate = (date) => {
                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const day = date.getDate();
                return `${year}/${month}/${day}`;
            };
            let currentDate = new Date(startDate);
            while (currentDate <= endDate) {
                const dateString = formatDate(currentDate);
                const haveTest = dates.includes(dateString);
                let subjects = [];

                if (haveTest) {
                    subjects = state.schedules.find(
                        (item) => item.date === dateString
                    ).subjects;
                    if (
                        user._id === "0801540143" ||
                        user._id === "9901123456"
                    ) {
                        [...subjects].forEach((subject) => {
                            subject.show = true;
                        });
                    }
                    subjects = [...subjects].map((subject) => {
                        let score = -1;
                        if (user.examrecords) {
                            let record = [...user.examrecords].find(
                                (record) =>
                                    record.date === dateString &&
                                    record.subjectno ===
                                        subject.subjectno.toString()
                            );
                            if (record) {
                                score = Number(record.score);
                            }
                        }
                        return { ...subject, score };
                    });
                }

                result.push({
                    date: dateString,
                    haveTest,
                    subjects,
                });

                currentDate.setDate(currentDate.getDate() + 1);
            }

            return result;
        }

        return generateDates(
            [...state.schedules].map((s) => {
                return s.date;
            })
        );
    }, [state, user]);

    useEffect(() => {
        fetch(backendurl + "user/getReports", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                _id: user._id,
            }),
        })
            .then(async (response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json().then((data) => {
                        throw new Error(data.message);
                    });
                }
            })
            .then((data) => {
                setReports(data.message);
                if (data.message) {
                    [...data.message].forEach((r) => {
                        if (r["notice"]) {
                            setUnRead(true);
                            return;
                        }
                    });
                }
            })
            .catch((error) => {
                alert(error.message);
            });
        return () => {};
    }, [user]);

    const handleDateClick = (date) => {
        setSelectedDate(date);
        setTimeout(() => {
            setShowModal(true);
        }, 500);
    };

    const closeModal = () => {
        setShowModal(false);
    };
    const openReport = () => {
        setShowReport(true);
    };
    const closeReport = () => {
        setShowReport(false);
    };

    const scrollToBottom = () => {
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const scrollTop = () => {
        topRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    const loadReports = () => {
        fetch(backendurl + "user/getReports", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                _id: user._id,
            }),
        })
            .then(async (response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json().then((data) => {
                        throw new Error(data.message);
                    });
                }
            })
            .then((data) => {
                setReports(data.message);
                if (data.message) {
                    [...data.message].forEach((r) => {
                        if (r["notice"]) {
                            setUnRead(true);
                            return;
                        }
                    });
                }
            })
            .catch((error) => {
                alert(error.message);
            });
    };

    return (
        <div className={style.page}>
            <div className={style.part1} ref={topRef}>
                <div className={style.examname}>{state.examName}</div>

                <div className={style.scheduleblock}>
                    {generatedDates.map((item, index) => (
                        <DateButton
                            key={index}
                            delay={index * 100}
                            date={item}
                            setdate={handleDateClick}
                        />
                    ))}
                </div>
                <div className={style.btrow}>
                    <div className={style.showdatabt} onClick={scrollToBottom}>
                        統計數據
                    </div>
                    <div
                        className={`${style.reportbt} ${
                            unRead ? style.unread : null
                        }`}
                        onClick={openReport}
                    >
                        問題反饋
                    </div>
                </div>
            </div>
            {showModal && (
                <EventModal
                    onClose={closeModal}
                    eventDetails={selectedDate}
                    examid={examid}
                    examname={state.examName}
                />
            )}

            {showReport && (
                <ReportModal
                    onClose={closeReport}
                    schedules={state.schedules}
                    reports={reports}
                    examid={examid}
                    loadReports={loadReports}
                />
            )}

            <StatisticBlock
                r={bottomRef}
                records={user.examrecords}
                rank={state.rank}
                schedules={state.schedules}
                id={user._id}
                scrollTop={scrollTop}
            />
        </div>
    );
};
