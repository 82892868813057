import React from "react";
import style from "./ResultDisplayer.module.css";

export default function ResultDisplayer({ questions, records, piclink }) {
    const pic = (q, i) => {
        return q[`img${i}`] === "有" ? (
            <>
                {i === 1 ? <br /> : null}
                <img
                    className={style.optionpic}
                    alt="loading"
                    src={`${piclink}${q["QuesINO"]}-${i - 1}`}
                ></img>
            </>
        ) : (
            ""
        );
    };

    const Ansstyle = (q, i) => {
        const record = records.find((record) => record.questionno === i);
        if (!record || record.answer === "") {
            return (
                <>
                    <span className={style.wrongQ}>{q["QuesDesc1"]}</span>
                    {pic(q, 2)}
                    <br />
                    <span className={style.wrongQ}>{q["QuesDesc2"]}</span>
                    {pic(q, 3)}
                    <br />
                    <span className={style.wrongQ}>{q["QuesDesc3"]}</span>
                    {pic(q, 4)}
                    <br />
                    <span className={style.wrongQ}>{q["QuesDesc4"]}</span>
                    {pic(q, 5)}
                    <br />
                    <span className={style.wrongQ}>{q["QuesDesc5"]}</span>
                    {pic(q, 6)}
                </>
            );
        } else {
            const isRight = (ans) => {
                if (record.answer === ans) {
                    if (q["AnsQ"] === record.answer) {
                        return style.rightQ;
                    } else {
                        return style.wrongQ;
                    }
                } else {
                    return null;
                }
            };
            return (
                <>
                    <span className={isRight("A")}>{q["QuesDesc1"]}</span>
                    {pic(q, 2)}
                    <br />
                    <span className={isRight("B")}>{q["QuesDesc2"]}</span>
                    {pic(q, 3)}
                    <br />
                    <span className={isRight("C")}>{q["QuesDesc3"]}</span>
                    {pic(q, 4)}
                    <br />
                    <span className={isRight("D")}>{q["QuesDesc4"]}</span>
                    {pic(q, 5)}
                    <br />
                    <span className={isRight("E")}>{q["QuesDesc5"]}</span>
                    {pic(q, 6)}
                </>
            );
        }
    };

    return (
        <div className={style.main}>
            <div className={style.toprow}>標準答案</div>
            <div className={style.scrollable}>
                <table className={style.table}>
                    <tbody>
                        {questions.map((q, index) => {
                            return (
                                <tr key={index} className={style.row}>
                                    <td className={style.ansblock}>
                                        {q["AnsQ"]}
                                    </td>
                                    <td className={style.qblock}>
                                        {index + 1 + ". "}
                                        {q["QuesDesc"]}
                                        {pic(q, 1)}
                                        <br />
                                        {Ansstyle(q, index)}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
