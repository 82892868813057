import React, { useEffect, useState } from "react";
import style from "./StatisticBlock.module.css";

export default function StatisticBlock({
    r,
    records,
    rank,
    schedules,
    id,
    scrollTop,
}) {
    const [best, setBest] = useState({ score: -Infinity, name: "" });
    const [worst, setWorst] = useState({ score: Infinity, name: "" });
    const [average, setAverage] = useState(0);
    const [pr, setpr] = useState(0);

    useEffect(() => {
        if (!records || records.length === 0) {
            setAverage(0);
            setBest({ score: 0, name: "" }); // 假设负无穷为最低分
            setWorst({ score: 0, name: "" }); // 假设正无穷为最高分
            return;
        }

        let { total, bestRecord, worstRecord } = records.reduce(
            (acc, record) => {
                let score = Number(record.score);
                acc.total += score;
                if (score > Number(acc.bestRecord.score)) {
                    acc.bestRecord = record;
                }
                if (score < Number(acc.worstRecord.score)) {
                    acc.worstRecord = record;
                }

                return acc;
            },
            {
                total: 0,
                bestRecord: { score: -Infinity, name: "" },
                worstRecord: { score: Infinity, name: "" },
            }
        );

        try {
            let { date, subjectno } = bestRecord;
            let name = [...schedules].find((schedule) => schedule.date === date)
                .subjects[Number(subjectno) - 1].name;
            name = name.split("：")[0];
            bestRecord = { ...bestRecord, name };
        } catch {}
        try {
            let { date, subjectno } = worstRecord;
            let name = [...schedules].find((schedule) => schedule.date === date)
                .subjects[Number(subjectno) - 1].name;
            name = name.split("：")[0];
            worstRecord = { ...worstRecord, name };
        } catch {}
        setAverage((total / records.length).toFixed(1));
        setBest(bestRecord);
        setWorst(worstRecord);
    }, [records, schedules]);

    useEffect(() => {
        if (rank) {
            let self = [...rank].findIndex((r) => r === id);
            setpr(self / rank.length);
        }

        return () => {};
    }, [rank, id]);

    return (
        <div ref={r} className={style.allstatistic}>
            <div className={style.backbt} onClick={scrollTop}>
                返回作答頁面
            </div>
            <OneStatistic
                title={"完成進度"}
                main={records ? records.length : 0}
                sub={
                    schedules
                        ? "/" + schedules.length.toString() + "回"
                        : "/0回"
                }
                wait={0}
            />
            <OneStatistic
                title={"平均分數"}
                main={average}
                sub={"pr" + pr}
                smalltxt={"分"}
                wait={1000}
            />
            <OneStatistic
                title={"最強科目"}
                main={best.score}
                sub={best.name}
                smalltxt={"分"}
                wait={2000}
            />
            <OneStatistic
                title={"需要加油"}
                main={worst.score}
                sub={worst.name}
                smalltxt={"分"}
                wait={3000}
            />
        </div>
    );
}

function OneStatistic({ title, main, sub, smalltxt, wait }) {
    const [show, setShow] = useState(false);

    useEffect(() => {
        let delayshow = setTimeout(() => {
            setShow(true);
        }, wait);

        return () => {
            clearTimeout(delayshow);
        };
    }, [wait]);

    return (
        <div className={`${style.statisticblock} ${show ? style.show : null}`}>
            <div className={style.statistictitle}>{title}</div>
            <div className={style.statisticmain}>
                {main} <span className={style.statisticsmall}>{smalltxt}</span>
            </div>
            <div className={style.statisticsub}>{sub}</div>
        </div>
    );
}
