import { createContext, useContext, useState, useEffect } from "react";
import { backendurl } from "../assests/globalVariable";
import { useNavigate, useParams, useLocation } from "react-router-dom";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setuser] = useState({});
    const { examid } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const savedData = sessionStorage.getItem("userData");
        if (savedData) {
            const userData = JSON.parse(savedData);
            if (userData && Object.keys(userData).length !== 0) {
                setuser(userData);
                setIsLoggedIn(true);
            }
        } else if (
            !location.pathname.includes("login") &&
            !location.pathname.includes("setting")
        ) {
            alert("登錄時間過長，請重新登錄");
            navigate("/login/" + examid);
        }
    }, [navigate, examid, location.pathname]);

    const login = (id, password, exid, passingData) => {
        fetch(`${backendurl}user`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                username: id,
                password: password,
                examid: exid,
            }),
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    alert("網頁錯誤");
                }
            })
            .then((data) => {
                console.log(data);
                if (data.success) {
                    setuser(data.message);
                    sessionStorage.setItem(
                        "userData",
                        JSON.stringify(data.message)
                    );
                    navigate("/home/" + exid, {
                        state: { ...passingData },
                    });
                    setIsLoggedIn(true);
                } else {
                    alert(data.message);
                }
            });
    };

    const logout = () => {
        sessionStorage.removeItem("userData");
        setuser({});
        setIsLoggedIn(false);
    };

    const updateRecord = (userid, examid) => {
        fetch(`${backendurl}user/updateRecords`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                userid,
                examid,
            }),
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    window.location.href = "/login";
                }
            })
            .then((data) => {
                console.log(data);
                if (data.success) {
                    setuser({ ...user, examrecords: data.message });
                    sessionStorage.setItem(
                        "userData",
                        JSON.stringify({ ...user, examrecords: data.message })
                    );
                }
            });
    };

    return (
        <AuthContext.Provider
            value={{ isLoggedIn, login, logout, user, updateRecord }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
