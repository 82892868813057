import React from "react";
import style from "./Controlbt.module.css";

export default function Controlbt({
    func1,
    func2,
    func3,
    showUp,
    showDown,
    className,
}) {
    return (
        <div className={`${style.controlbts} ${className}`}>
            <button
                className={style.controlbt}
                onClick={func1}
                disabled={showUp}
            >
                <svg
                    className={style.pic1}
                    width="1rem"
                    height="1rem"
                    viewBox="-0.5 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M8 13.8599L10.87 10.8C11.0125 10.6416 11.1868 10.5149 11.3815 10.4282C11.5761 10.3415 11.7869 10.2966 12 10.2966C12.2131 10.2966 12.4239 10.3415 12.6185 10.4282C12.8132 10.5149 12.9875 10.6416 13.13 10.8L16 13.8599"
                        stroke="var(--fontcolor)"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M3 7.41992L3 17.4199C3 19.6291 4.79086 21.4199 7 21.4199H17C19.2091 21.4199 21 19.6291 21 17.4199V7.41992C21 5.21078 19.2091 3.41992 17 3.41992H7C4.79086 3.41992 3 5.21078 3 7.41992Z"
                        stroke="var(--fontcolor)"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
                上一題
            </button>
            <button
                className={style.controlbt}
                onClick={func2}
                disabled={showDown}
            >
                <svg
                    width="1.1rem"
                    height="1.1rem"
                    className={style.pic1}
                    viewBox="-0.5 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M16 10.99L13.1299 14.05C12.9858 14.2058 12.811 14.3298 12.6166 14.4148C12.4221 14.4998 12.2122 14.5437 12 14.5437C11.7878 14.5437 11.5779 14.4998 11.3834 14.4148C11.189 14.3298 11.0142 14.2058 10.87 14.05L8 10.99"
                        stroke="var(--fontcolor)"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M21 17.4199V7.41992C21 5.21078 19.2091 3.41992 17 3.41992L7 3.41992C4.79086 3.41992 3 5.21078 3 7.41992V17.4199C3 19.6291 4.79086 21.4199 7 21.4199H17C19.2091 21.4199 21 19.6291 21 17.4199Z"
                        stroke="var(--fontcolor)"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
                下一題
            </button>
            <button className={style.controlbt} onClick={func3}>
                <svg
                    width="1.2rem"
                    height="1.2rem"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M8.46967 8.46972C8.76256 8.17683 9.23744 8.17683 9.53033 8.46972L12 10.9394L14.4697 8.46973C14.7626 8.17684 15.2374 8.17684 15.5303 8.46973C15.8232 8.76262 15.8232 9.2375 15.5303 9.53039L13.0607 12.0001L15.5303 14.4697C15.8232 14.7626 15.8232 15.2375 15.5303 15.5304C15.2374 15.8233 14.7626 15.8233 14.4697 15.5304L12 13.0607L9.53034 15.5304C9.23744 15.8233 8.76257 15.8233 8.46968 15.5304C8.17678 15.2375 8.17678 14.7626 8.46968 14.4697L10.9393 12.0001L8.46967 9.53038C8.17678 9.23749 8.17678 8.76262 8.46967 8.46972Z"
                        fill="var(--fontcolor)"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.31673 3.76882C10.4043 3.42374 13.5957 3.42374 16.6832 3.76882C18.5096 3.97294 19.9845 5.41159 20.1994 7.24855C20.5686 10.4055 20.5686 13.5947 20.1994 16.7516C19.9845 18.5885 18.5096 20.0272 16.6832 20.2313C13.5957 20.5764 10.4043 20.5764 7.31673 20.2313C5.49035 20.0272 4.01545 18.5885 3.8006 16.7516C3.43137 13.5947 3.43137 10.4055 3.8006 7.24855C4.01545 5.41159 5.49035 3.97294 7.31673 3.76882ZM16.5166 5.25954C13.5398 4.92683 10.4602 4.92683 7.48334 5.25954C6.33891 5.38744 5.42286 6.29069 5.29045 7.4228C4.93476 10.4639 4.93476 13.5362 5.29045 16.5773C5.42286 17.7094 6.33891 18.6127 7.48334 18.7406C10.4602 19.0733 13.5398 19.0733 16.5166 18.7406C17.6611 18.6127 18.5771 17.7094 18.7095 16.5773C19.0652 13.5362 19.0652 10.4639 18.7095 7.4228C18.5771 6.29069 17.6611 5.38744 16.5166 5.25954Z"
                        fill="var(--fontcolor)"
                    />
                </svg>
                取消作答
            </button>
        </div>
    );
}
